import React from 'react'
import {
  makeStyles,
  Typography,
  MenuItem,
  Select as MUISelect,
  withStyles,
  InputBase,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const CustomInput = withStyles((theme) => ({
  root: {
    height: 50,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    width: '100%',
    padding: '14px 26px 14px 12px',

    background: '#FFFFFF',
    border: '1px solid #CFCFCF',
    borderRadius: 5,
    // color: '#B4B4B4',
    color: 'black',

    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: 12,

    // '&:placeholder': {
    //   color: 'red'
    // },

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)
const useStyles = makeStyles(() => ({
  inputGroup: {
    '& label': {
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        color: '#000000',
        marginBottom: 5,
      },
    },
  },
}))
export default function Select({
  value,
  placeholder,
  id,
  label,
  onChange,
  options,
}) {
  const classes = useStyles({ value })
  return (
    <div className={classes.inputGroup}>
      <label htmlFor={id}>
        <Typography variant='body1'>{label}</Typography>
        <MUISelect
          input={<CustomInput />}
          variant='outlined'
          value={value}
          id={id}
          type='text'
          placeholder={placeholder}
          displayEmpty={!!placeholder}
          onChange={(event) => {
            onChange(event, id)
          }}
        >
          <MenuItem disabled value='' key='nullItem'>
            <Typography style={{ color: '#B4B4B4' }}>{placeholder}</Typography>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MUISelect>
      </label>
    </div>
  )
}

Select.propTypes = {
  /**
   * Controlled value of the input
   */
  value: PropTypes.string.isRequired,

  /**
   * Key used to match label to input. Will be passed back with the event in the onChange callback
   */
  id: PropTypes.string.isRequired,

  /**
   * Callback fired when input value changes. Passed the event and the 'id' prop
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Placeholder value
   */
  placeholder: PropTypes.string,

  /**
   * Label for the input
   */
  label: PropTypes.string,

  /**
   * Array of select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
}
Select.defaultProps = {
  placeholder: undefined,
  label: undefined,
}
