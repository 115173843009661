/* eslint-disable no-nested-ternary */
import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  inputGroup: {
    width: '100%',

    '& label': {
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        color: (props) => (props.error ? '#f46367' : '#000000'),
      },
    },
  },
  input: {
    boxSizing: 'border-box',

    height: 45,
    padding: '0px 12px',
    marginTop: 5,

    borderRadius: 5,
    background: '#FFFFFF',
    border: (props) =>
      props.error ? '1px solid #f46367' : '1px solid #CFCFCF',

    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: 12,

    '&::placeholder': {
      color: '#B4B4B4',
    },

    '&:focus': {
      outline: 'none',
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  textArea: {
    boxSizing: 'border-box',

    padding: '12px',
    height: '100%',
    minHeight: 45,
    marginTop: 5,

    borderRadius: 5,
    background: '#FFFFFF',
    border: '1px solid #CFCFCF',

    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: 12,

    resize: 'none',

    '&::placeholder': {
      color: '#B4B4B4',
    },

    '&:focus': {
      outline: 'none',
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  errorHint: {
    color: '#f46367',
    marginLeft: 10,
    marginTop: 5,
    fontSize: 12,
  },
}))
export default function TextInput({
  value,
  placeholder,
  id,
  label,
  onChange,
  variant,
  style,
  className,
  error,
  errorHint,
}) {
  const classes = useStyles({ error })
  return (
    <div className={`${classes.inputGroup} ${className}`} style={style}>
      <label htmlFor={id}>
        <Typography variant='body1'>{label}</Typography>
        {variant === 'textarea' ? (
          <textarea
            className={classes.textArea}
            value={value}
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event, id)
            }}
            rows={6}
          />
        ) : variant === 'email' ? (
          <input
            className={classes.input}
            value={value}
            id={id}
            type='email'
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event, id)
            }}
          />
        ) : (
          <input
            className={classes.input}
            value={value}
            id={id}
            type='text'
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event, id)
            }}
          />
        )}
      </label>
      {error && errorHint && (
        <Typography variant='body1' className={classes.errorHint}>
          {errorHint}
        </Typography>
      )}
    </div>
  )
}

TextInput.propTypes = {
  /**
   * Controlled value of the input
   */
  value: PropTypes.string.isRequired,

  /**
   * Key used to match label to input. Will be passed back with the event in the onChange callback
   */
  id: PropTypes.string.isRequired,

  /**
   * Callback fired when input value changes. Passed the event and the 'id' prop
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Placeholder value
   */
  placeholder: PropTypes.string,

  /**
   * Label for the input
   */
  label: PropTypes.string,

  /**
   * Variant to switch between regular text input and textarea
   */
  variant: PropTypes.oneOf(['default', 'textarea']),

  /**
   * Variant to switch between regular text input and textarea
   */
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,

  /**
   * Variant to switch between regular text input and textarea
   */
  className: PropTypes.string,

  /**
   * If true, the text input has an error
   */
  error: PropTypes.bool,

  /**
   * Text to display when the input 'error' prop is true
   */
  errorHint: PropTypes.string,
}

TextInput.defaultProps = {
  placeholder: undefined,
  label: undefined,
  variant: 'default',
  style: {},
  className: '',
  error: false,
  errorHint: '',
}
