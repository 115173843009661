/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import constants from './constants'

export function submitContactForm(formValues) {
  const contact = {
    name: formValues.fullName,
    email: formValues.email,
    inquiry_type: formValues.inquiryType,
    message: formValues.message,
  }
  return axios.post(constants.CONTACT, contact)
}
