import React from 'react'
import { makeStyles, Typography, Link } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
// import MediumLogo from '../assets/shared/CycurID Socials/medium_logo.png'
import LinkedInLogo from '../assets/shared/CycurID Socials/linkedin_logo.png'
import TwitterLogo from '../assets/shared/CycurID Socials/twitter_logo.png'
import FacebookLogo from '../assets/shared/Imme Socials/facebook_logo.png'
import InstagramLogo from '../assets/shared/Imme Socials/instagram_logo.png'
import ContactForm from '../components/ContactForm'
import ContactUs from '../components/ContactUs'

const Background = '../assets/About/Images/contactUsBackground.png'

const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ========================= */
  sectionTitle: {
    marginBottom: 15,
    maxWidth: 280,

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
    },
  },
  sectionDescription: {
    textAlign: 'center',
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },

  /**
   * || Hero
   * ================= */
  hero: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 550,
    top: -50, // Account for the header bar height
    marginBottom: 900,
    // paddingTop: 48,
    // paddingLeft: theme.gutters.mobile,
    // paddingRight: theme.gutters.mobile,

    // backgroundColor: '#F0F4F8',

    [theme.breakpoints.up('tablet')]: {
      marginBottom: 560,
    },

    [theme.breakpoints.up('900')]: {
      marginBottom: 480,
    },

    [theme.breakpoints.up('desktop')]: {
      marginBottom: 640,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginBottom: 600,
      height: 750,
    },
  },
  /**
   * Contains the .heroTitle and .heroDescription
   */
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 110,
    width: '100%',
    padding: 35,
    paddingTop: 100,
    paddingBottom: 200,

    textAlign: 'center',
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('tablet')]: {
      paddingTop: 'auto',
      paddingBottom: 'auto',
      padding: 55,
    },
    [theme.breakpoints.up('desktop')]: {
      padding: 100,
      marginTop: 150,
    },
    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 150,
    },
  },
  heroTitle: {
    color: 'white',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 800,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 840,
    },
  },
  heroDescription: {
    maxWidth: 550,
    // margin: '20px 0px',
    marginTop: 20,

    color: 'white',
    whiteSpace: 'pre-line',
  },

  /**
   * || Contact body and form
   * ============================= */
  contactCard: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',

    maxWidth: 575,
    width: '90%',
    margin: 'auto',
    top: 460,

    background: '#FFFFFF',
    boxShadow: '0px 4px 18px rgba(107, 107, 107, 0.25)',
    borderRadius: 10,

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',

      maxWidth: 850,
      top: 460,
    },

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',

      maxWidth: 1050,
      top: 600,
    },

    [theme.breakpoints.up('desktopXL')]: {
      top: 690,
    },
  },
  contactForm: {
    display: 'flex',
    flex: 7,
    flexDirection: 'column',
    alignItems: 'center',

    padding: '50px 20px 20px 20px',

    [theme.breakpoints.up('tablet')]: {
      padding: '50px 40px 20px 40px',
      alignItems: 'flex-start',
    },

    '& h2': {
      marginBottom: 15,

      textAlign: 'center',

      [theme.breakpoints.up('tablet')]: {
        marginBottom: 25,
        textAlign: 'left',
      },
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '50px 50px 20px 50px',
    },
  },
  contactSidebar: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    background: 'transparent',
    padding: 50,
    borderTop: '1px solid #E1E1E1',
    zIndex: 1,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    [theme.breakpoints.up('tablet')]: {
      alignItems: 'flex-start',

      borderTop: 'none',
      borderLeft: '1px solid #E1E1E1',
    },

    '& a': {
      marginTop: 5,

      color: '#737373',
    },

    '& h4': {
      color: '#4A4A4A',
    },

    '& h3': {
      marginBottom: 12,
    },
  },
  linkCategory: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginBottom: 35,

    [theme.breakpoints.up('tablet')]: {
      alignItems: 'flex-start',
    },
  },

  /**
   * Contains several .socialButtons
   */
  socialLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: 10,

    '& :nth-child(2)': {
      marginRight: 5,
      marginLeft: 5,
    },

    '& img': {
      width: 20,
      height: 20,
    },
  },
  socialButton: {
    padding: 0,

    borderRadius: 10,
  },

  linkTitle: {
    color: '#737373',
    fontWeight: 600,
    fontSize: 14,
  },
  imageContainer: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
  },
  bgImage: {
    width: '100%',
    height: '100%',
  },
}))

export default function Contact() {
  const classes = useStyles()

  return (
    <main>
      <section className={classes.hero}>
        <div className={classes.heroContent}>
          <Typography variant='h1' className={classes.heroTitle}>
            It’s time to equip your business with the tools you need to thrive
            in the digital age.
          </Typography>

          <Typography variant='body1' className={classes.heroDescription}>
            If you’re ready to learn how we can put CycurID to work for your
            business, we’d love to hear from you and answer any questions you
            may have.
          </Typography>
        </div>
        <div className={classes.contactCard}>
          <div className={classes.contactForm}>
            <Typography variant='h2'>Send Us A Message</Typography>
            <ContactForm />
          </div>

          <div className={classes.contactSidebar}>
            <div className={classes.imageContainer}>
              <StaticImage
                className={classes.bgImage}
                src={Background}
                alt='tech background'
                placeholder='blurred'
                objectFit='fill'
                quality={100}
              />
            </div>
            <Typography variant='h3'>Follow us on our socials</Typography>{' '}
            <br />
            <div className={classes.linkCategory}>
              <Typography variant='h4' className={classes.linkTitle}>
                CycurID Socials
              </Typography>
              <div className={classes.socialLinkContainer}>
                <Link
                  href='https://www.linkedin.com/company/cycurid'
                  className={classes.socialButton}
                  target='_blank'
                  rel='noopener'
                >
                  <img
                    className={classes.socialIcon}
                    src={LinkedInLogo}
                    alt='linkedin logo'
                  />
                </Link>
                <Link
                  href='https://twitter.com/CycurId'
                  className={classes.socialButton}
                  target='_blank'
                  rel='noopener'
                >
                  <img
                    className={classes.socialIcon}
                    src={TwitterLogo}
                    alt='twitter logo'
                  />
                </Link>
              </div>
            </div>
            {/* <div className={classes.linkCategory}>
              <Typography variant='h4' className={classes.linkTitle}>
                Imme Socials
              </Typography>
              <div className={classes.socialLinkContainer}>
                <Link
                  href='https://www.facebook.com/GetYourImmeId/'
                  className={classes.socialButton}
                  target='_blank'
                  rel='noopener'
                >
                  <img
                    className={classes.socialIcon}
                    src={FacebookLogo}
                    alt='facebook logo'
                  />
                </Link>
                <Link
                  href='https://www.instagram.com/getyourimmeid/'
                  className={classes.socialButton}
                  target='_blank'
                  rel='noopener'
                >
                  <img
                    className={classes.socialIcon}
                    src={InstagramLogo}
                    alt='instagram logo'
                  />
                </Link>
                <Link
                  href='https://twitter.com/getyourimmeid'
                  target='_blank'
                  rel='noopener'
                  className={classes.socialButton}
                >
                  <img
                    className={classes.socialIcon}
                    src={TwitterLogo}
                    alt='twitter logo'
                  />
                </Link>
              </div>
            </div>
            <Typography variant='h4' className={classes.linkTitle}>
              Visit our Imme Website
            </Typography>
            <Link href='https://imme.io/' target='_blank' rel='www.imme.io'>
              https://www.imme.io
            </Link> */}
          </div>
        </div>
      </section>
      <ContactUs />
    </main>
  )
}
