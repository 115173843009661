import React, { useState } from 'react'
import {
  makeStyles,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Send as SendIcon } from '@material-ui/icons'
import TextInput from './inputs/TextInput'
import Select from './inputs/Select'
import { submitContactForm } from '../api/contact'
import emailValidator from '../utils/emailValidator'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    '& > div': {
      marginBottom: 20,
    },
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexRowNoMessage: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButtonWrapper: {
    position: 'relative',
    paddingRight: 12,
  },
  submitButtonWrapperNoMessage: {
    position: 'relative',
    paddingRight: 12,
  },

  fabProgress: {
    color: '#2FA43B',
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  sendIconButton: {
    marginLeft: 'auto',
    padding: 5,
    width: 40,
    height: 40,

    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    backgroundColor: (props) => (props.submitting ? '#358981' : '#2FA43B'),

    '&:hover': {
      backgroundColor: '#358981',
    },
    '& svg': {
      margin: '0px 0px 4px 2px',

      color: 'transparent',
      transform: 'rotate(-40deg)',
      stroke: 'white',
    },

    '&:disabled': {
      backgroundColor: '#2FA43B',
      opacity: 0.6,
    },
  },
  flexContainer: {
    [theme.breakpoints.up('900')]: {
      display: 'flex',
      width: '100%',
    },
  },
  nameInput: {
    marginBottom: 20,

    [theme.breakpoints.up('900')]: {
      marginRight: 20,
      marginBottom: 0,
    },
  },

  errorMsg: {
    color: '#f46367',
    fontSize: 10,

    [theme.breakpoints.up('tablet')]: {
      fontSize: 12,
    },
  },

  submittedMsg: {
    color: theme.palette.primary.main,
    fontSize: 10,

    [theme.breakpoints.up('tablet')]: {
      fontSize: 12,
    },
  },
}))

const inquiryTypeOptions = [
  {
    value: 'GENERAL_INQUIRY',
    label: 'General Inquiry',
  },
  {
    value: 'SUPPORT',
    label: 'Support Request',
  },
  {
    value: 'BUG_REPORTING',
    label: 'Bug Reporting',
  },
  { value: 'MEDIA_INQUIRY', label: 'Media Inquiry' },
  {
    value: 'PARTNER_REQUEST',
    label: 'Partner Inquiry',
  },
  {
    value: 'INVEST_REQUEST',
    label: 'Investor Inquiry',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
]
const initialFormValues = {
  fullName: '',
  email: '',
  inquiryType: '',
  message: '',
}
export default function ContactForm() {
  const [formValues, setFormValues] = useState(initialFormValues)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
  })
  const classes = useStyles({ submitting })
  const isFormDisabled = () => {
    const someValueNotSet = Object.keys(formValues).some((key) => {
      // Skip the optional message key
      if (key === 'message') {
        return false
      }
      return (
        formValues[key] === null ||
        formValues[key] === false ||
        formValues[key] === ''
      )
    })
    return submitting || someValueNotSet // || (useCaptcha && !verified)
  }

  const onChange = (event, id) => {
    const { value } = event.target
    setFormValues({ ...formValues, [id]: value })

    // Clear error if field is changed
    if (errors[id] != null) {
      setErrors({
        ...errors,
        [id]: '',
      })
    }

    setSubmitted(false)
    setErrorMessage('')
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMessage('') // Clear any existing errors

    setSubmitting(true)

    // Ensure email is valid
    if (!emailValidator(formValues.email)) {
      setErrors({ ...errors, email: 'Please enter a valid email address' })
      setSubmitting(false)
      return
    }
    try {
      await submitContactForm(formValues)

      setSubmitted(true)
      setFormValues(initialFormValues)

      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      setSubmitted(false)
      setErrorMessage('An error has occurred. Please try again later.')
    }
  }

  return (
    <form noValidate autoComplete='off' className={classes.form}>
      <div className={classes.flexContainer}>
        <TextInput
          onChange={onChange}
          value={formValues.fullName}
          placeholder='Adam Jonas'
          id='fullName'
          label='Full Name'
          className={classes.nameInput}
        />
        <TextInput
          error={!!errors.email}
          errorHint={errors.email}
          onChange={onChange}
          value={formValues.email}
          placeholder='example.text@email.com'
          id='email'
          label='Email'
        />
      </div>

      <Select
        onChange={onChange}
        value={formValues.inquiryType}
        placeholder='Select One'
        id='inquiryType'
        label='Inquiry Type'
        options={inquiryTypeOptions}
      />

      <TextInput
        onChange={onChange}
        value={formValues.message}
        placeholder='Type Here'
        id='message'
        label='Message (Optional)'
        variant='textarea'
      />

      <div
        className={
          submitted || errorMessage ? classes.flexRow : classes.flexRowNoMessage
        }
      >
        {submitted && (
          <Typography variant='h4' className={classes.submittedMsg}>
            Thank you for contacting us. <br />
            We will be in touch with you shortly.
          </Typography>
        )}
        {errorMessage && (
          <Typography variant='body1' className={classes.errorMsg}>
            {errorMessage}
          </Typography>
        )}
        <div
          className={
            submitted || errorMessage
              ? classes.submitButtonWrapper
              : classes.submitButtonWrapperNoMessage
          }
        >
          <IconButton
            className={classes.sendIconButton}
            onClick={handleSubmit}
            disabled={isFormDisabled()}
            aria-label='Send Message'
          >
            <SendIcon />
          </IconButton>
          {submitting && (
            <CircularProgress size={48} className={classes.fabProgress} />
          )}
        </div>
      </div>
    </form>
  )
}
